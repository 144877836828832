<template>
    <div class="">
        <div
            v-for="file in documents[$root.incomingData.theme]"
            :key="file.title"
            @click="$modals.open('docsModal', {title: file.title, link: file.link, pdfLink: file.pdfLink})"
            class="block-link pointer d-flex align-center mt-6"
        >
            <div class="block-logo info-block-img mr-4">
                <!--                <img src="@/assets/img/pdf-icon.svg" alt="" />-->
                <img class="scaled-img" v-if="$root.incomingData.theme === 'purple'" src="@/assets/img/docs-purple.svg"
                     alt=""/>
                <img v-else src="@/assets/img/docs.svg" alt=""/>
            </div>

            <h4 class="main-title">{{ file.title }}</h4>

            <img
                class="arrow-right"
                src="@/assets/img/arrow-right.svg"
                alt=""
            />
        </div>



    </div>
</template>

<script>
export default {
    data: () => ({
        documents: {
            purple: [
                {
                    title: "Условия Сервиса «По частям»",
                    link: "https://anketa.pochastiam.ru/storage/docs/renessanse/rules.pdf",
                },
                {
                    title: "Заявление на возврат товара",
                    link: "https://anketa.pochastiam.ru/storage/docs/renessanse/refund.pdf",
                }
            ],
            blue: [
                {
                    title: "Договор поручения по приобретению и оплате товаров, работ и услуг",
                    link: "https://local-bnpl.mk-mobile.online/storage/docs/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D0%BF%D0%BE%D1%80%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BF%D0%BE_%D0%BF%D1%80%D0%B8%D0%BE%D0%B1%D1%80%D0%B5%D1%82%D0%B5%D0%BD%D0%B8%D1%8E_%D0%B8_%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B5_%D1%82%D0%BE%D0%B2%D0%B0%D1%80%D0%BE%D0%B2,%D1%80%D0%B0%D0%B1%D0%BE%D1%82_%D0%B8_%D1%83%D1%81%D0%BB%D1%83%D0%B3.pdf",
                },
                {
                    title: "Согласие на обработку персональных данных",
                    link: "https://local-bnpl.mk-mobile.online/storage/docs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95_%D0%9D%D0%90_%D0%9E%D0%91%D0%A0%D0%90%D0%91%D0%9E%D0%A2%D0%9A%D0%A3_%D0%9F%D0%95%D0%A0%D0%A1%D0%9E%D0%9D%D0%90%D0%9B%D0%AC%D0%9D%D0%AB%D0%A5_%D0%94%D0%90%D0%9D%D0%9D%D0%AB%D0%A5.pdf",
                }
            ],
            pink: [
                {
                    title: "Условия поручения по приобретению и оплате товаров, работ и услуг",
                    link: "https://anketa.paylate.ru/storage/docs/mk-mobile/Usloviya_porucheniya_po_priobreteniyu_i_oplate_tovarov_rabot_i_uslug.pdf",
                },
                {
                    title: "Согласие на обработку персональных данных",
                    link: "https://paylate.ru/docs/agreement_bnpl.pdf",
                },
                {
                    title: 'Общие условия соглашения о новации',
                    link: "https://anketa.paylate.ru/storage/docs/mk-mobile/OBSHCHIE_USLOVIYA_SOGLASHENIYA_O_NOVATSII.pdf"
                },
                {
                    title: 'Заявление на полное досрочное погашение',
                    link: "https://paylate.ru/docs/application_early_repayment_mkm.pdf"
                },
                {
                    title: 'Заявление на частичное досрочное погашение',
                    link: "https://paylate.ru/docs/agreed_partial_payment_mkm.pdf"
                }
            ]
        },
    }),

    mounted() {
        this.$connector.getDocument()
            .then(data => {
                this.documents.pink[2].pdfLink = 'data:application/pdf;base64,' + encodeURI(data.DocData)
                // this.documents.pink.push({
                //     pdfLink : ,
                // })
            })
    }
}
</script>

<style scoped></style>
